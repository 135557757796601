import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import DEFPurchaseTermsAndConditionsStyled from '../components/page-styles/def-purchase-terms-and-conditions-styles';

const DEFPurchaseTermsAndConditions = ({ path }) => (
    <Layout path={path}>
        <SEO title="About Us | Saratoga" />
        <DEFPurchaseTermsAndConditionsStyled>
            <section>
                <span id="updated-date">Updated 7.12.2024</span>
                <h2 className="terms__header">
                    DEF PURCHASE TERMS &amp; CONDITIONS
                </h2>
                <p className="terms__copy">
                    The following DEF Purchase Terms and Conditions (“Terms and
                    Conditions”) apply to the purchase of Products from Seller
                    to Buyer as set forth in the Sales Confirmation. These Terms
                    and Conditions and the Sales Confirmation comprise the
                    entire agreement between the parties (collectively, the
                    “Agreement”). Buyer accepts these Terms and Conditions by
                    signing and returning Seller’s quotation, by sending a
                    purchase order in response to the quotation, or by Buyer’s
                    instructions to Seller to ship and/or deliver the Product.
                    No terms, conditions or warranties other than those
                    identified in the quotation and no agreement or
                    understanding, oral or written, in any way purporting to
                    modify the Terms and Conditions whether contained in Buyer's
                    purchase order or shipping release forms, or elsewhere,
                    shall be binding on Seller unless hereafter made in writing
                    and signed by Seller's authorized representative. Buyer is
                    hereby notified of Seller’s express rejection of any terms
                    inconsistent with this Agreement or to any other terms
                    proposed by Buyer in accepting Seller’s quotation. Neither
                    Seller's subsequent lack of objection to any terms, nor the
                    delivery of the products or services, shall constitute an
                    agreement by Seller to any terms. PURCHASE OF DEF AND OTHER
                    ITEMS AND SERVICES FROM SARATOGA IS SUBJECT TO AND
                    CONSTITUTES AND ACKNOWLEDGES BUYER’S ACCEPTANCE OF, THESE
                    TERMS AND CONDITIONS, AS MAY BE AMENDED FROM TIME TO TIME AS
                    SET FORTH HEREIN. PLEASE READ THESE TERMS AND CONDITIONS
                    CAREFULLY. THESE TERMS AND CONDITIONS ARE SUBJECT TO CHANGE
                    FROM TIME TO TIME IN SARATOGA’S SOLE DISCRETION. THESE TERMS
                    AND CONDITIONS, AND ANY UPDATES THERETO, CAN BE ACCESSED BY
                    BUYER AT ANY TIME AT THE FOLLOWING WEB ADDRESS:&nbsp;
                    <Link to="def-purchase-terms-and-conditions/">
                        www.saratogarack.com/def-purchase-terms-and-conditions/
                    </Link>
                </p>
                <h4 className="terms__sub-header">1. Definitions</h4>
                <p className="terms__copy">
                    The below words have the following meanings: “Account(s)”
                    means the credit extended to Buyer by Saratoga. “Business
                    Day” means any day other than a Saturday, Sunday or other
                    day on which banking institutions are generally authorized
                    or required by law or executive order to close. “Buyer” is
                    the Buyer entity who has applied for and been approved for
                    an account with Saratoga as identified in the quotation,
                    credit application, proposal, order acknowledgement, or
                    invoice. “DEF” shall reference Diesel Exhaust Fluid, 32.5%
                    UREA solution/bulk. The DEF shall meet or exceed the
                    applicable quality specifications for DEF as set forth in
                    the International Organization for Standardization (ISO
                    22241-1:2006(E)) specifications for DEF. “Product(s)” shall
                    reference DEF or any other form or concentration of UREA and
                    other ancillary products and services purchased by Buyer
                    from Seller. “Sales Confirmation” shall mean any and all of
                    the following documents between Buyer and Seller: quotation,
                    credit application, proposal, order acknowledgement, or
                    invoice. “Seller” shall reference Saratoga Rack Marketing
                    LLC.
                </p>
                <h4 className="terms__sub-header">2. Ordering Product</h4>
                <p className="terms__copy">
                    2.1 Seller agrees to sell Product in such quantities as may
                    be agreed to between the parties from time to time. Unless
                    otherwise agreed to between the parties, there is no minimum
                    quantity for purchase or sale hereunder.
                    <br />
                    <br />
                    2.2. Buyer may order Product from Saratoga by placing
                    telephone or email orders as needed to Seller’s corporate
                    headquarters. Upon acceptance, the order will be confirmed
                    as to the quantity, price, and the place and/or mode of
                    delivery.
                    <br />
                    <br />
                    2.3. In the event any significant Product cost component
                    changes occur, the parties agree to meet and confer to
                    arrive at a mutually agreed upon price adjustment. The
                    following examples are events that would qualify as a
                    significant cost component change; however, this provision
                    is not limited to these examples: (i) Product specification
                    changes, (ii) changes in applicable laws or regulations,
                    (iii) changes in Adder costs or (iv) supply issues.
                </p>
                <h4 className="terms__sub-header">
                    3. Terms of Payment and Credit
                </h4>
                <p className="terms__copy">
                    3.1. Buyer agrees to pay for all Product purchased from
                    Seller when due at the price supplied to Buyer upon Buyer’s
                    order of Product. Buyer will pay for all Product within the
                    agreed upon payment term as set forth in the invoice
                    (“Payment Period”).
                    <br />
                    <br />
                    3.2. Seller may in its sole discretion extend credit to
                    Buyer after a complete and accurate credit application has
                    been completed. Seller may at any time, investigate the
                    financial condition of Buyer or, as applicable, its
                    subsidiaries and affiliates. If requested, Buyer agrees to
                    furnish Seller copies of its financial statements, or other
                    financial information as soon as available. Financial
                    statements shall have been prepared in accordance with
                    generally accepted accounting principles, consistently
                    applied and shall be in accordance with the books and
                    records of Buyer. Failure to present requested financial
                    information may result in the termination of Buyer’s
                    Account. Nothing herein shall obligate Seller to grant
                    credit to Buyer. Seller may at any time refuse to grant
                    credit to Buyer or condition the granting of credit on such
                    additional credit terms as Seller in its sole discretion may
                    require.
                    <br />
                    <br />
                    3.3. If Seller does not receive Buyer’s payment within the
                    Payment Period, Buyer’s account shall be considered
                    delinquent and the account will be subject to late charges
                    at the rate of eighteen percent (18%) per annum or at the
                    highest legal rate, whichever is lower, retroactive to the
                    date of the unpaid invoice of invoices, or any portions
                    thereof remaining unpaid. Seller will begin to assess late
                    fees on the first day following the date a payment is due
                    and is not posted to the Account by the end of the business
                    day. The late fee will apply to all past due amounts. Should
                    any transfer, check, or other payment from Buyer be returned
                    to Seller, Buyer agrees that it will be charged a $50.00
                    processing fee for each payment returned to Buyer for
                    insufficient funds or for any other reason which would cause
                    the funds to be unavailable to Seller.
                    <br />
                    <br />
                    3.4. Buyer’s Account may be shut off, effective immediately,
                    in Seller’s sole discretion, for nonpayment. Further, Seller
                    shall not be obligated to supply Product to Buyer.
                    <br />
                    <br />
                    3.5. Payments will be applied first to unpaid late fees and
                    then to any unpaid balances. In addition to any other rights
                    now or hereafter granted under applicable law, Seller may,
                    without notice, set off and apply any and all monies, and
                    any and all other indebtedness at any time held by Seller to
                    or for Buyer’s credit or account, against or on account of
                    any indebtedness owed by Buyer to Seller or any of Buyer’s
                    other obligations and liability to Seller, howsoever
                    arising.
                    <br />
                    <br />
                    3.6. If Buyer believes an invoice contains information that
                    is incorrect, it must bring the discrepancy to Seller’s
                    attention in writing within the Payment Period or timely pay
                    the invoice in full. If Buyer does bring a discrepancy to
                    Seller’s attention in writing within the Payment Period,
                    Buyer must pay Seller the undisputed portion of the invoice
                    within the Payment Period. All discrepancies or disputes of
                    charges must be sent in writing to Seller within sixty (60)
                    days from the invoice date or they will be considered final
                    and binding and unconditionally waived by Buyer.
                    <br />
                    <br />
                    3.7. Buyer is responsible for all transactions made by its
                    current and former employees, contractors, affiliates,
                    subsidiaries, or subcontractors who run Transactions or use
                    Buyer’s Account number. It is Buyer’s responsibility to
                    monitor all Account activity.
                </p>
                <h4 className="terms__sub-header">
                    4. Ownership/Delivery Terms/Risk of Loss
                </h4>
                <p className="terms__copy">
                    4.1. Where delivery is F.O.B. “Ship From” location, title
                    and risk of loss will pass to Buyer at the “Ship From”
                    location as Product passes (as applicable) the transport
                    truck or railcar inlet flange, barge permanent hose
                    connection, or pipeline upstream flange. Title and risk of
                    loss will not be affected by Seller’s ownership of the
                    transportation assets, arrangement of shipment, and/or
                    pre-payment or collection of shipment expenses from Buyer.
                    Seller will have no obligation to deliver Product at the
                    “Ship From” location unless Buyer, its agents, and its
                    carriers have entered into, and are in compliance with,
                    agreements governing access to the “Ship From” location.
                    <br />
                    <br />
                    4.2. Where delivery is F.O.B. the “Ship To” destination,
                    title and risk of loss will pass to Buyer at the “Ship To”
                    destination as Product passes (as applicable) the transport
                    truck or railcar outlet flange, barge permanent hose
                    connection, or pipeline downstream flange. Where shipment is
                    by railcar, and Buyer is unable to accept delivery of the
                    railcar when offered, then title and risk of loss will pass
                    to Buyer at the time of constructive placement of the
                    railcar. Buyer will pay and be responsible for any
                    demurrage, fleeting, shifting, parking, detention, port or
                    other charges related to receipt or delivery of Product,
                    unless solely caused by Seller.
                </p>
                <h4 className="terms__sub-header">5. Non-Compliant Product</h4>
                <p className="terms__copy">
                    5.1. If Buyer believes that any Product purchased from
                    Seller hereunder fails to meet the specifications set forth
                    herein, Buyer will notify Seller in writing within seven (7)
                    days after the date of the transfer of the title of such
                    Product to Buyer; provided, however, that in the event that
                    upon or after such delivery the Product has been used or
                    commingled with other products or materials, such claims
                    with respect to the Product’s quality are waived. If Buyer
                    fails to provide Seller with a defect notice for any Product
                    sold hereunder within the applicable defect notice period,
                    such Product shall, for all purposes, be deemed to have been
                    fully compliant with all specifications at the time of the
                    title transfer from Seller to Buyer. Seller shall not be
                    responsible or liable in any way to Buyer for any Product
                    sold hereunder that is alleged to vary from the
                    specifications set forth herein unless Seller has timely
                    received a defect notice with respect to such Product.
                    <br />
                    <br />
                    5.2. If based upon a timely defect notice, it is determined
                    that Seller has supplied Buyer with Product that is not
                    within the specifications set forth herein, Buyer’s sole
                    remedies and Seller’s sole responsibility shall be for
                    Seller, at its option, to (i) refund the purchase price paid
                    by Buyer for the non-confirming Product, or (ii) replace any
                    such non-conforming Product with Product that conforms to
                    the specifications set forth herein.
                </p>
                <h4 className="terms__sub-header">
                    6. Warranties and Limitation of Warranties
                </h4>
                <p className="terms__copy">
                    Buyer represents that it is familiar with the
                    characteristics, qualities, and uses of the Product and that
                    Buyer is not relying on the Seller's skill or judgment to
                    select or furnish Product suitable for any particular
                    purpose. Seller warrants that the Product sold under this
                    Agreement conforms to the description in the Sales
                    Confirmation and that Seller has title to the Product at the
                    time of its delivery free of any liens or encumbrances.
                    Buyer assumes all risk of use of the Product, either alone
                    or in combination with other materials, except for the risk
                    that the Product does not meet the specifications shown on
                    the front page of this Agreement. Except as set forth in
                    this paragraph, there are no REPRESENTATIONS OR WARRANTIES
                    OF ANY KIND, express or implied by operation of law or
                    otherwise, including but not limited to any warranty as to
                    merchantability or fitness for any particular purpose OR ANY
                    OTHER MATTER WITH RESPECT TO THE PRODUCT, whether used alone
                    or in combination with any other material.
                </p>
                <h4 className="terms__sub-header">
                    7. Limitation on Liability/Claims and Indemnification
                </h4>
                <p className="terms__copy">
                    7.1. NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR ANY
                    SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
                    (INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFIT OR REVENUE,
                    BUSINESS INTERRUPTIONS, OR FAILURE OF SUPPLY) RESULTING FROM
                    ANY BREACH OF ITS OBLIGATIONS UNDER THIS AGREEMENT.
                    <br />
                    <br />
                    7.2. Buyer shall indemnify and hold Seller harmless from and
                    against all claims, loss, liability and expense (including
                    but not limited to reasonable attorney's fees and litigation
                    or investigation costs) on account of or in any way arising
                    out of any damage to property or injury or death of persons
                    (including Buyer's employees) arising out of Buyer's
                    loading, unloading, transportation, storage, handling, use,
                    or disposal of the Product or any other occurrence arising
                    out of Buyer's business to the extent such damage to
                    property or injury or death of persons is not caused in
                    whole or in part by Seller’s gross negligence or willful
                    misconduct.
                    <br />
                    <br />
                    7.3. Seller shall not be liable for any loss, damage,
                    injury, or other casualty to the person or property of Buyer
                    or any of Buyer’s employees, agents, common carriers, or
                    contractors, arising out of or by reason of any transfer of
                    Product made hereunder at any Location, unless such loss,
                    damage, injury, or other casualty is caused solely by the
                    gross negligence or willful misconduct of Seller.
                </p>
                <h4 className="terms__sub-header">8. Default</h4>
                <p className="terms__copy">
                    8.1. A party to this Agreement may terminate this Agreement
                    at any time upon the default of the other party. “Default”
                    means: (i) the failure of the Buyer to remit payment to
                    Seller in accordance with the terms of this Agreement; (ii)
                    the breach by either party of this Agreement, other than an
                    event of nonpayment by Buyer, provided the breach is not
                    remedied within 7 days of the defaulting party's receipt of
                    written notice from the other party specifying the breach;
                    (iii) the representation or warranty by either party of any
                    facts in connection with this Agreement that prove to have
                    been materially incorrect or misleading when the
                    representation or warranty was made; (iv) the filing by or
                    against either party of any petition in bankruptcy,
                    insolvency, receivership, or reorganization or pursuant to
                    any other debtor relief law or the entry of any order
                    appointing a receiver, custodian, trustee, liquidator, or
                    any other person with similar authority over the assets of
                    either party; (v) the insolvency, dissolution,
                    reorganization, assignment for the benefit of creditors or
                    any other material adverse change in the financial condition
                    of either party; (vii) the entry of any adverse judgment,
                    order or award against either party that has a material
                    adverse impact on the financial condition of either party or
                    a detrimental effect on the ability of either party to
                    perform its obligations; (viii) Buyer fails to make
                    arrangements for payment as Seller may reasonably require,
                    or (ix) a material adverse change occurs in the financial or
                    other condition of Buyer and such material adverse change in
                    the reasonable opinion of Seller makes it unlikely Buyer
                    will be able to perform all or any of its obligations to
                    Seller or (x) the default by Buyer under any other agreement
                    between Buyer and Seller.
                    <br />
                    <br />
                    8.2. If Buyer Defaults, Seller may, in its sole discretion,
                    (i) stop any Product in transit and defer any further
                    deliveries or require cash in advance of any delivery until
                    Seller has been satisfied of Buyer’s ability to pay or
                    creditworthiness, (ii) suspend Buyer’s ability to further
                    borrow under this Agreement; (iii) declare all outstanding
                    amounts under the Account are immediately due and payable;
                    (iv) terminate this Agreement with immediate effect; and (v)
                    C bring suit and exercise all rights and remedies available
                    under applicable law which may include the payment of all
                    reasonable costs of collection.
                </p>
                <h4 className="terms__sub-header">
                    9. Permits, Fees, and Taxes
                </h4>
                <p className="terms__copy">
                    Seller and Buyer, respectively, shall procure all permits,
                    licenses, and other authorizations that may be required in
                    order to perform its respective obligations under this
                    Agreement. Any tax, license fee, inspection fee, or other
                    charges imposed by any agency or governmental authority and
                    required to be paid on, or measured by gross receipts from,
                    any Product sold pursuant to this Agreement or on the
                    production, manufacture, sale, use, delivery, or other
                    handling of such Product or any component thereof, existing
                    at the time of any delivery or transfer hereunder, shall be
                    added to the price specified on the face hereof and shall be
                    paid by Buyer or reimbursed to Seller by Buyer. In the event
                    Seller is required by law to collect and/or pay such items,
                    Buyer shall promptly reimburse Seller for the same upon
                    receipt of Seller’s invoice. Buyer shall furnish Seller with
                    tax exemption certificates where it claims an exemption is
                    due.
                </p>
                <h4 className="terms__sub-header">10. Assignment</h4>
                <p className="terms__copy">
                    This Agreement may not be assigned by Buyer. Seller may
                    assign this Agreement upon written notice to Buyer.
                </p>
                <h4 className="terms__sub-header">11. Force Majeure</h4>
                <p className="terms__copy">
                    Neither party shall be liable for any delay or failure in
                    performing its obligations hereunder, other than the payment
                    of money, to the extent that such delay or failure is caused
                    by any event or condition beyond the reasonable control of
                    such party, including, but not limited to, an Act of God;
                    strike; lockout; riot; civil commotion; combination of
                    workers; breakdown or other mechanical difficulties of
                    vessels used in the deliveries of Product; fire; shortage of
                    facilities, fuel, materials, or labor; governmental
                    restriction or prohibition of exports or imports;
                    governmental blockade or hostility; governmental seizure or
                    expropriation; war or revolution; or the closure of
                    international trade routes. The affected party shall notify
                    the other party as soon as possible after Force Majeure
                    occurs. If, due to Force Majeure or any other cause, Seller
                    is unable to obtain or to produce sufficient Product to meet
                    its internal needs and the requirements of its Buyers,
                    Seller will in its reasonable discretion allocate its
                    Product in a manner it deems to be fair and reasonable
                    considering its internal needs, needs of its affiliates, and
                    its regular Buyers; and such allocation shall be binding on
                    all parties.
                </p>
                <h4 className="terms__sub-header">12. Notices</h4>
                <p className="terms__copy">
                    All notices, requests, demands and other communications to
                    Seller shall be in writing and shall be deemed given on (a)
                    the date on which it is delivered by hand to 5508 Lonas
                    Drive, Knoxville, TN 37909; (b) two (2) days after it is
                    post marked and deposited in the mail, postage prepaid; (c)
                    one (1) day after it is placed with a recognized overnight
                    carrier or (d) the date on which it is sent by electronic
                    mail to wholesalenotifications@tartanoil.com with copy to
                    mailbox.legal@pilottravelcenters.com and with confirmed
                    receipt, or at such other addresses as the Parties may
                    designate by written notice in the manner aforesaid.
                    <br />
                    All notices, requests, demands and other communications to
                    Buyer shall be in writing and shall be deemed given on (a)
                    the date on which it is delivered by hand at the address
                    specified in the Sales Confirmation; (b) two (2) days after
                    it is post marked and deposited in the mail, postage
                    prepaid; (c) one (1) day after it is placed with a
                    recognized overnight carrier or (d) the date on which it is
                    sent by electronic mail with confirmed receipt, at the
                    addresses specified in the Sales Confirmation, or at such
                    other addresses as the Parties may designate by written
                    notice in the manner aforesaid.
                </p>
                <h4 className="terms__sub-header">
                    13. Amendments, Headings and Severability
                </h4>
                <p className="terms__copy">
                    All additions and amendments to this Agreement shall be in
                    writing and signed by both parties. All headings contained
                    in this Agreement are for reference purposes only and shall
                    not be deemed to form a part of the Agreement nor affect the
                    interpretation thereof. If any provision of this Agreement
                    is or becomes illegal, void, or invalid, that provision
                    shall not affect the legality of the other provisions.
                </p>
                <h4 className="terms__sub-header">14. Confidentiality</h4>
                <p className="terms__copy">
                    Except as may be agreed to in writing by the other Party, as
                    may be necessary to perform its obligations herein, or as
                    may be required by law, each party shall maintain in
                    confidence all information concerning costs and price to be
                    disclosed in connection with the Agreement, shall not use
                    such information other than as necessary to perform its
                    obligations or exercise its rights under this Agreement, and
                    shall not disclose such information other than to its
                    employees and professional advisors with a need to know such
                    information, and such employees and professional advisors
                    shall be advised of the confidential nature of such
                    information. Notwithstanding the foregoing, nothing in this
                    section or this Agreement prohibits Seller from providing
                    any information to its affiliates or third-party servicers
                    in connection with Buyer’s account or other programs or
                    services to be offered by Seller or its affiliates, and
                    Buyer expressly agrees to these disclosures and use of
                    information. From time to time, such information will be
                    shared by and between Seller and other third parties in
                    accordance with normal business practices including, but not
                    limited to, to approve or decline Buyer’s application,
                    servicing and/or maintaining Buyer’s account(s), the
                    exchange of trade credit information, evaluating Buyer’s
                    transactions and payment experiences, opening and/or closing
                    account(s), credit decisions, and to market additional
                    Seller products and services to Buyer, unless Buyer desires
                    to opt out of the sharing of its information by providing
                    written notice of such intent to Seller.
                </p>
                <h4 className="terms__sub-header">15. No Waiver</h4>
                <p className="terms__copy">
                    The failure or delay of a Party to enforce any power or
                    right under the Agreement shall not be deemed a waiver of
                    that power or right or operate as a bar to the exercise or
                    enforcement of such power or right at any time or times
                    thereafter, nor shall any single or partial enforcement of
                    any such power or right preclude any other or further
                    exercise of that power or right or the exercise of any other
                    power or right under the Agreement. No waiver by either
                    Party of any default or breach of any agreement, covenant,
                    condition, or stipulation herein by the other Party shall be
                    treated as a waiver of any subsequent default or breach of
                    the same or another agreement, covenant, condition, or
                    stipulation hereof.
                </p>
                <h4 className="terms__sub-header">
                    16. No Implied Relationship
                </h4>
                <p className="terms__copy">
                    Nothing contained in or done pursuant to the Agreement shall
                    be deemed or construed by the Parties, or by any third
                    party, to create the relationship of principal and agent,
                    partnership, joint venture, or any association whatsoever
                    between the Buyer and the Seller.
                </p>
                <h4 className="terms__sub-header">17. Misuse of Products</h4>
                <p className="terms__copy">
                    Except as expressly agreed in writing between the Parties,
                    the Product shall not be used in (i) the production or
                    processing of livestock feed, food products for human
                    consumption (other than as fertilizers), or pharmaceutical
                    products; or (ii) the use as feed by livestock, food
                    products for direct human consumption, or pharmaceutical
                    products. Any such use shall be deemed a misuse of the
                    Product. Buyer acknowledges that the bulk nature of the
                    Product makes it impractical for Seller to include labels or
                    warnings regarding the misuse of the Product, and Buyer
                    represents that following its purchase of the Product it
                    will include such warnings and labels as are necessary and
                    practical.
                </p>
                <h4 className="terms__sub-header">
                    18. Waiver of Jury Trial and Class Action Rights
                </h4>
                <p className="terms__copy">
                    EACH PARTY IRREVOCABLY WAIVES, TO THE FULLEST EXTENT
                    PERMITTED BY APPLICABLE LAW, ANY RIGHT IT MAY HAVE TO A
                    TRIAL BY JURY IN ANY LEGAL PROCEEDING DIRECTLY OR INDIRECTLY
                    ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE
                    TRANSACTIONS CONTEMPLATED HEREBY (WHETHER BASED IN CONTRACT,
                    TORT, OR ANY OTHER THEORY). ANY CLAIM BROUGHT BY BUYER
                    AGAINST SELLER MUST BE BROUGHT IN THE BUYER’S INDIVIDUAL
                    CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                    PURPORTED CLASS, COLLECTIVE, REPRESENTATIVE, MULTIPLE
                    PLAINTIFF, OR SIMILAR PROCEEDING (“CLASS ACTION”). BUYER
                    EXPRESSLY WAIVES ANY ABILITY TO MAINTAIN OR PARTICIPATE IN
                    ANY CLASS ACTION IN ANY FORUM.
                </p>
                <h4 className="terms__sub-header">19. Governing Law</h4>
                <p className="terms__copy">
                    This Agreement shall be governed by and construed in
                    accordance with Delaware law, without regard to any
                    conflicts of law.
                </p>
                <h4 className="terms__sub-header">
                    20. Attorneys’ Fees & Costs
                </h4>
                <p className="terms__copy">
                    Buyer shall be liable to Seller for any and all attorneys’
                    fees and costs incurred by Seller to enforce this Agreement.
                </p>
                <h4 className="terms__sub-header">21. Covenants</h4>
                <p className="terms__copy">
                    21.1. The parties represent and warrant that this Agreement
                    constitutes the legal, valid, binding, and enforceable
                    agreement of each party, and its execution and performance
                    of this Agreement: (i) does not constitute a breach of any
                    agreement of either party with any other party, or of any
                    duty arising in law or equity; (ii) does not violate any
                    law, rule or regulation applicable to it; (iii) is within
                    the party's corporate powers; and (iv) has been authorized
                    by all necessary corporate action of the parties. Buyer
                    agrees to provide any evidence of corporate (or other
                    organizational) existence and authorization that Seller may
                    reasonably request, in its sole discretion. Failure of
                    Seller to request evidence of corporate (or organizational)
                    existence may not be used against Seller.
                    <br />
                    <br />
                    21.2. For Seller’s continued compliance with banking and
                    credit underwriting standards, Buyer will provide Seller
                    with advance written notice of: (i) any change in Seller’s
                    legal structure or legal name; (ii) any consolidation,
                    merger or sale of a substantial part of Seller’s assets; or
                    (iii) any change of control of Seller as well as if an
                    employee of Seller that has provided a guaranty of payment
                    has terminated their relationship with Buyer. Seller
                    reserves the right to make any necessary modifications to
                    the Account terms based upon changes made by Buyer as
                    referenced above.
                </p>
                <span id="copyright">
                    &copy; {new Date().getFullYear()} Saratoga Rack Marketing
                    LLC
                </span>
            </section>
        </DEFPurchaseTermsAndConditionsStyled>
    </Layout>
);

export default DEFPurchaseTermsAndConditions;
