import styled from 'styled-components';

const DEFPurchaseTermsAndConditionsStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 130px;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    #updated-date,
    .terms__header,
    .terms__sub-header,
    .terms__copy,
    #copyright {
        width: 100%;
    }

    #updated-date {
        display: block;
        font-size: 18px;
        font-style: italic;
        margin-bottom: 10px;
    }

    h2.terms__header {
        margin-bottom: 24px;
        font: 900 24px sofia-pro, sans-serif;
    }

    .terms__sub-header {
        display: block;
        margin: 16px 0;
        font: 900 18px sofia-pro, sans-serif;
        text-transform: uppercase;
    }

    .terms__copy {
        margin-bottom: 20px;
        font-size: 16px;
        text-align: justify;
        line-height: 25px;
    }

    #copyright {
        margin-bottom: 80px;
        font-family: sofia-pro, sans-serif;
        font-size: 12px;
    }
`;

export default DEFPurchaseTermsAndConditionsStyled;
